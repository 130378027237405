import axios from 'axios';
import {empty, from, Observable, of, throwError} from "rxjs";
import {filter, map, mergeMap, tap} from 'rxjs/operators';
import {Account, AccountBasic, AccountForAdmin, PaymentType} from "./domain/account";
import {Logger} from "./domain/logger";
import {SecretKey, Session, User, UserForAdmin, UserSettings} from "./domain/user";
import {Invoice} from "./domain/invoice";
import {Observation, SeriesFilter} from "./domain/observations";
import {Sensor} from "./domain/sensor";
import {ApiToken, SystemToken} from "./domain/token";
import {SystemLoggerMetadata} from "./domain/systemMetadata";
import {ActivationState, Module, SyncSetting, SyncSettings} from "./domain/module";
import {Ism} from "./domain/ism";
import {message} from "antd";

interface ResponseHandler<T> {
    onResult(result: T): void;
    onError(errorCode: number, message: string, data: any): void;
}

/**
 * Designed to support being used as a string for backward compatibility with lots of code that
 * assumed the error from a request is a string.
 */
class JsonRpcError extends String {
    code: number;
    message: string;
    data: any;

    constructor (code: number, message: string, data: any) {
        super(message);
        this.code = code
        this.message = message
        this.data = data
    }

}

class RpcClient {
    private HOST = process.env.REACT_APP_HOST_URL as string
    private API_VERSION = "10.3"
    private requestId = 1;

    public sendRequest<T>(method: string, params: object = {}): Observable<T> {
        const request = {
            jsonrpc: '2.0',
            version: this.API_VERSION,
            id: ++this.requestId,
            method,
            params
        }
        // console.log("SENDING REQUEST: " + JSON.stringify(request, null, 2))
        return from(axios.post(this.HOST, request)) // TODO - This isn't actually reactive, sends request immediately
            .pipe(mergeMap(response => {
                if ('result' in response.data) {
                    return of(response.data.result);
                } else {
                    return throwError(new JsonRpcError(response.data.error.code, response.data.error.message,
                        response.data.error.data ? response.data.error.data : undefined));
                }
            }))
    }

    public sendRequestAsync<T>(method: string, params: object = {}, handler: ResponseHandler<T>): void {
        const request = {
            jsonrpc: '2.0',
            version: this.API_VERSION,
            id: ++this.requestId,
            method,
            params
        }
        axios.post(this.HOST, request)
            .then(response => {
                if ('result' in response.data) {
                    handler.onResult(response.data.result as T)
                } else {
                    handler.onError(response.data.error.code, response.data.error.message, response.data.error.data)
                }
            })
    }


}

class ApiClient extends RpcClient {

    private static singletonInstance: ApiClient;

    public getTime(): Observable<Date> {
        return this.sendRequest<string>("time")
            .pipe(map(result => {
                return new Date(result)
            }))
    }

    public createAccount(name: string, email: string, phone: string, number: string, expMonth: string,  expYear: string, cvc: string): Observable<SecretKey> {
        return this.sendRequest<SecretKey>("createAccount", {name, email, phone, creditCard : {number, expMonth, expYear, cvc}})
    }

    public getAccount(secretKey: string): Observable<Account> {
        return this.sendRequest<Account>("getAccount", {secretKey})
            .pipe(map((account) => {
                // Convert strings to dates
                for (let token of account.apiTokens) {
                    token.created = token.created ? new Date(token.created) : token.created
                    token.lastAccess = token.lastAccess ? new Date(token.lastAccess) : token.lastAccess
                }
                return account
            }))
    }

    public getAccountAsync(secretKey: string, handler: ResponseHandler<Account>): void {
        return this.sendRequestAsync("getAccount", {secretKey}, new class implements ResponseHandler<Account> {
            onError(errorCode: number, message: string, data: any): void {
                handler.onError(errorCode, message, data)
            }
            onResult(account: Account): void {
                // Convert strings to dates
                if (account.apiTokens !== undefined) {
                    for (let token of account.apiTokens) {
                        token.created = token.created ? new Date(token.created) : token.created
                        token.lastAccess = token.lastAccess ? new Date(token.lastAccess) : token.lastAccess
                    }
                }
                handler.onResult(account)
            }
        }())
    }

    public getAccountBasic(apiToken: string): Observable<AccountBasic> {
        return this.sendRequest("getAccountBasic", {apiToken})
    }

    public updateAccount(secretKey: string, name: string, email: string, phone: string): Observable<Account> {
        return this.sendRequest<Account>("updateAccount", {secretKey, name, email, phone});
    }

    public updateCreditCard(secretKey: string, creditCardInfo: {number: string, cvc: string, expMonth: string, expYear: string} | null): Observable<Account> {

        // If creditCardInfo is a null, this request attempts to remove the credit card from the account; otherwise, we
        // are setting the account's credit card to the credit card that is passed in
        return this.sendRequest<Account>("updateAccount", {secretKey, creditCard :  creditCardInfo})
    }

    public getAllInvoiceIds(secretKey: string): Observable<number[]> {
        return this.sendRequest("findInvoices", {secretKey});
    }

    public getCurrentInvoice(secretKey: string) : Observable<Invoice> {
        return this.sendRequest<Invoice>("getCurrentInvoice", {secretKey})
            .pipe(map((invoice) => {
                invoice.created = invoice.created ? new Date(invoice.created) : invoice.created
                invoice.billDateThreshold = invoice.billDateThreshold ? new Date(invoice.billDateThreshold) : invoice.billDateThreshold
                invoice.lineItemTotal = 0
                for (let lineItem of invoice.lineItems) {
                    lineItem.date = lineItem.date ? new Date(lineItem.date) : lineItem.date
                    invoice.lineItemTotal += lineItem.lineTotalCents
                }
                return invoice
        }))
    }

    public getInvoice(secretKey: string, invoiceNumber: number): Observable<Invoice> {
        return this.sendRequest<Invoice>("getInvoice", {secretKey, invoiceNumber})
            .pipe(map((invoice) => {
                invoice.created = invoice.created ? new Date(invoice.created) : invoice.created
                invoice.lineItemTotal = 0
                invoice.paidDate = invoice.paidDate ? new Date(invoice.paidDate) : invoice.paidDate

                for (let lineItem of invoice.lineItems) {
                    lineItem.date = lineItem.date ? new Date(lineItem.date) : lineItem.date
                    invoice.lineItemTotal += lineItem.lineTotalCents
                }

                for (let payment of invoice.payments) {
                    payment.date = payment.date ? new Date(payment.date) : payment.date
                }

                return invoice
            }))
    }

    public createUser(email: string, password: string, name: string): Observable<boolean> {
        return this.sendRequest<boolean>("createUser", {email, password, name})
    }

    public loginGoogleSSO(token: string, handler: ResponseHandler<string>) {
        return this.sendRequestAsync<string>("authenticateUserGoogle", {token}, handler)
    }

    public loginMicrosoftSSO(token: string, handler: ResponseHandler<string>) {
        return this.sendRequestAsync<string>("authenticateUserMicrosoft", {token}, handler)
    }

    public resetPassword(email: string): Observable<boolean> {
        return this.sendRequest<boolean>("resetPassword", {email})
    }

    public authenticateUser(email: string, password: string, handler: ResponseHandler<string>) {
        return this.sendRequestAsync<string>("authenticateUser", {email, password}, handler)
    }

    public getUser(sessionAuthToken: string): Observable<User> {
        return this.sendRequest<User>("getUser", {sessionAuthToken})
    }

    public updateUser(sessionAuthToken: string, email: string | null, name: string | null, settings: UserSettings | null): Observable<User> {
        let output = {} as User;
        if (email !== null)
            output.email = email;
        if (name !== null)
            output.name = name;
        if (settings !== null)
            output.settings = settings
        return this.sendRequest<User>("updateUser", {...output, sessionAuthToken})
    }

    public updatePassword(sessionAuthToken: string, oldPassword: string, newPassword: string): Observable<boolean> {
        return this.sendRequest<boolean>("updatePassword", {oldPassword: oldPassword, newPassword: newPassword, sessionAuthToken: sessionAuthToken})
    }

    public getUserAccounts(sessionAuthToken: string): Observable<Account[]> {
        return this.sendRequest<Account[]>("getUserAccounts", {sessionAuthToken})
            .pipe(map((accounts) => { // Sort by name for better display in many places
                return accounts.sort(((a, b) => (a.name ? a.name : '').localeCompare((b.name ? b.name : ''))))
            }))
    }

    public updateUserAccountAccessByToken(sessionAuthToken: string, apiAccessToken: string): Observable<string> {
        return this.sendRequest<string>('updateUserAccountAccess', {sessionAuthToken, key: apiAccessToken})
    }

    public removeUserAccountAccess(sessionAuthToken: string, accountId: string): Observable<boolean> {
        return this.sendRequest<boolean>('removerUserAccountAccess', {sessionAuthToken, accountId})
    }

    public getUserSession(sessionAuthToken: string): Observable<Session> {
        return this.sendRequest("getUserSession", {sessionAuthToken})
    }

    public updateUserSession(sessionAuthToken: string, updateProps: object): Observable<Session> {
        return this.sendRequest("updateUserSession", {...{sessionAuthToken}, ...updateProps})
    }

    public registerDevice(apiToken: string, serialId: string): Observable<boolean> {
        return this.sendRequest<object>("registerDevice", {apiToken, serialId})
            .pipe(map((result) => {
                return 'accessLevel' in result;
            }))
    }

    private _configureLogger(apiToken: string, serialId: string, params: object, fromAdmin: boolean): Observable<Object> {
        return this.sendRequest<object>(fromAdmin ? "configureLoggerAdmin" : "configureLogger", {...{...(fromAdmin ? {sessionAuthToken: apiToken} : {apiToken: apiToken}), serialId}, ...params})
    }


    private setLoggerState(apiToken: string, serialId: string, params: object): Observable<Object> {
        return this.sendRequest<object>("configureLogger", {apiToken, serialId, ...params})
            .pipe(map((result) => {
                return result;
            }))
    }

    public activateCellDevice(apiToken: string, serialId: string, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, {network: { cell: { state : "ACTIVE" }}}, fromAdmin)
            .pipe(map((result) => {
                // @ts-ignore
                return result.configuration.cell.state === "ACTIVE";
            }))
    }

    public deactivateCellDevice(apiToken: string, serialId: string, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, {network: { cell: { state : "INACTIVE" }}}, fromAdmin)
            .pipe(map((result) => {
                // @ts-ignore
                return result.configuration.cell.state === "ACTIVE";
            }))
    }

    public activateSatelliteDevice(apiToken: string, serialId: string, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, {network: { satellite: { state : "ACTIVE" }}}, fromAdmin)
            .pipe(map((result) => {
                // @ts-ignore
                return result.configuration.satellite.state === "ACTIVE";
            }))
    }

    public deactivateSatelliteDevice(apiToken: string, serialId: string, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, {network: { satellite: { state : "INACTIVE" }}}, fromAdmin)
            .pipe(map((result) => {
                // @ts-ignore
                return result.configuration.satellite.state === "ACTIVE";
            }))
    }

    public disableIsm(apiToken: string, serialId: string, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, {network: {ism: { enabled: false}}}, fromAdmin)
            .pipe(map((result) => {
                // @ts-ignore
                return result.configuration.ism?.enabled === false
            }))
    }


    public configureIsm(apiToken: string, serialId: string, ism: Ism, fromAdmin: boolean) : Observable<Object> {
        return this._configureLogger(apiToken, serialId, {network: {ism}}, fromAdmin)
    }

    public findLoggers(sessionAuthToken: string, params = {}): Observable<{deviceIds: string[], page: number, pageSize: number, totalPages: number}> {
        return this.sendRequest("findLoggers", {...{apiToken: sessionAuthToken}, ...params})
    }

    public findLoggersByText(sessionAuthToken: string, query: string): Observable<{deviceIds: string[], page: number, pageSize: number, totalPages: number}> {
        return this.sendRequest<{deviceIds: string[], page: number, pageSize: number, totalPages: number}>("findLoggersByText", {apiToken: sessionAuthToken, query: query, page: 1, pageSize: 1000})
            .pipe(
                // If there are more than 1 pages of results, return nothing and notify the user
                tap(result => {
                    if (result.totalPages > 1) {
                        message.error("Your query returned too many results. Please consider being more specific.");
                    }
                }),
                filter(result => result.totalPages <= 1),
                map(result => result)
            );
    }

    public findEndpointsAdmin(sessionAuthToken: string, params = {}): Observable<string[]> {
        return this.sendRequest("findEndpointsAdmin",  {...{sessionAuthToken: sessionAuthToken}, ...params})
    }

    public getLogger(apiToken: string, serialId: string, fromAdmin: boolean): Observable<Logger> {
        return this.sendRequest<Logger>(fromAdmin ? "getLoggerAdmin" : "getLogger", {...(fromAdmin ? {sessionAuthToken: apiToken} : {apiToken: apiToken}), serialId})
            .pipe(map((logger) => {
                // Convert date strings to dates
                logger.properties.lastComm = logger.properties.lastComm ? new Date(logger.properties.lastComm) : logger.properties.lastComm
                logger.properties.lastObservation = logger.properties.lastObservation ? new Date(logger.properties.lastObservation) : logger.properties.lastObservation
                logger.properties.lastUpdate = logger.properties.lastUpdate ? new Date(logger.properties.lastUpdate) : logger.properties.lastUpdate
                return new Logger(logger.serialId, logger.properties, logger.configuration, logger.network);
            }))
    }

    public configureLogger(apiToken: string, serialId: string, params: object, fromAdmin: boolean): Observable<boolean> {
        return this._configureLogger(apiToken, serialId, params, fromAdmin)
            .pipe(map((result) => "serialId" in result))
    }

    public findSensors(authToken: string, params = {}): Observable<string[]> {
        return this.sendRequest("findSensors", {...{apiToken: authToken}, ...params})
    }

    public getSensor(authToken: string, serialId: string): Observable<Sensor> {
        return this.sendRequest<Sensor>("getSensor", {apiToken: authToken, serialId})
    }

    public configureSensor(authToken: string, serialId: string | undefined, params: object): Observable<boolean> {
        return this.sendRequest<object>("configureSensor", {...{apiToken: authToken, serialId}, ...params})
            .pipe(map((result) => "serialId" in result))
    }

    public removeSensor(authToken: string, serialId: string): Observable<boolean> {
        return this.sendRequest<object>("removeSensor", {apiToken: authToken, serialId})
            .pipe(map((result) => "serialId" in result))
    }

    public getSensorCalibrationSheet(authToken: string, serialId: string): Observable<Blob> {
        return this.sendRequest("getSensorCalibration", {apiToken: authToken, serialId})
            .pipe(map((result: any) => {
                if (result?.pdf == null)
                    return new Blob()
                const byteArray = new Uint8Array(atob(result.pdf).split('').map(char => char.charCodeAt(0)));
                return new Blob([byteArray], {type: 'application/pdf'});
            }))
    }

    public createApiToken(sessionAuthToken: string, name: string): Observable<ApiToken> {
        return this.sendRequest("createApiToken", {secretKey: sessionAuthToken, name})
    }

    public updateApiToken(sessionAuthToken: string, params = {}): Observable<ApiToken> {
        return this.sendRequest("updateApiToken", {secretKey: sessionAuthToken, ...params})
    }

    public removeApiToken(sessionAuthToken: string, apiToken: string): Observable<boolean> {
        return this.sendRequest("removeApiToken", {secretKey: sessionAuthToken, apiToken})
    }

    /**
     *  Takes in a sorted list of emails to be associated with this account
     *
     * @param sessionAuthToken
     * @param billingEmail list of emails e.g ["test1@gmail.com", "test2@gmail.com"]
     *
     * @returns true if billing emails were successfully set
     */
    public setBillingAdminEmail(sessionAuthToken: string, billingEmail: string[]): Observable<boolean> {
        return this.sendRequest<object>("setBillingAdminEmail", {secretKey: sessionAuthToken, billingEmail: billingEmail})
            .pipe(map((result) => {
                // @ts-ignore
                return "billingEmail" in result && result.billingEmail === billingEmail;
            }))
    }

    /**
     * Returns a sort list of billing emails associated with this account
     *
     * @param sessionAuthToken
     */
    public getBillingAdminEmail(sessionAuthToken: string): Observable<string[]> {
        return this.sendRequest<object>("getBillingAdminEmail", {secretKey: sessionAuthToken})
            .pipe(map((result) => {
                // @ts-ignore
                return result.billingEmail;
            }))
    }

    public getObservations(apiToken: string, serialId: string, filters: object | null, startTime: Date | null, endTime: Date | null,
                           page: number, pageSize: number, metrics: string[], units: string[] | null, fromAdmin: boolean): Observable<Observation[]> {
        const params = {
            filters : filters,
            metrics: metrics,
            units: units,
            page: page,
            pageSize: pageSize,
            format: 1,
            ...(fromAdmin ? {sessionAuthToken: apiToken} : {apiToken: apiToken})
        }

        const endpoint = fromAdmin ? "getDataSeriesAdmin" : "getDataSeries";

        return this.sendRequest<object>(endpoint, {...{serialId}, ...params})
            .pipe(map((result) => {
                let obs: Observation[] = []
                // @ts-ignore
                const units: string[] = result.units
                // @ts-ignore
                for (const key in result.observations) {
                    const timestamp = new Date(key)
                    // @ts-ignore
                    let values: number[] = result.observations[key]
                    for (const i in values) {
                        if (values[i] !== null) { // Null is used in format 1 if metric has no val at timestamp
                            let ob: Observation = {
                                time: timestamp,
                                metric: metrics[i],
                                unit: units[i],
                                value: values[i],
                            }
                            obs.push(ob)
                        }
                    }
                }
                return obs
            }))
    }

    public getLastObservationTime(authToken: string, serialId: string, filters: object | null, metrics: string[], fromAdmin: boolean): Observable<Date> {
        return this.getObservations(authToken, serialId, filters, null, null, -1, 1, metrics, null, fromAdmin)
            .pipe(mergeMap((obs => {
                return obs.length > 0 ? of(obs[0].time) : empty()
            })))
    }

    public getObservationsCount(apiToken: string, serialId: string, filters: object | null, startTime: Date | null, endTime: Date | null,
                            metrics: string[], fromAdmin: boolean): Observable<number> {
        const params = {
            filters: filters,
            metrics: metrics,
            page: 1,
            pageSize: 1,
            ...(fromAdmin ? {sessionAuthToken: apiToken} : {apiToken: apiToken})
        };

        // Decide the API endpoint based on the admin status.
        const endpoint = fromAdmin ? "getDataSeriesAdmin" : "getDataSeries";

        return this.sendRequest<object>(endpoint, {...{serialId}, ...params})
            .pipe(map((result) => {
                // @ts-ignore
                return result.totalPages ? result.totalPages : 0
            }))
    }

    public findDataSeries(authToken: string, serialId: string, filters: object | null): Observable<SeriesFilter[]> {
        return this.sendRequest("findDataSeries", {...{apiToken: authToken, serialId}, ...filters})
            .pipe(map((result: any) => {
                return result.map((x: any) => x.filters)
            }))
    }

    public static get Instance() {
        return this.singletonInstance || (this.singletonInstance = new this());
    }

    // ~~~~~~~~~~~~~~~~~~ ADMIN QUERIES ~~~~~~~~~~~~~~~~~~ \\

    public findApiAccounts(sessionAuthToken: string): Observable<AccountForAdmin[]> {
        return this.sendRequest<AccountForAdmin[]>("findAccounts", {sessionAuthToken})
            .pipe(map((result) => {
                for (let account of result) {
                    account.created = account.created ? new Date(account.created) : account.created
                    account.lastAccess = account.lastAccess ? new Date(account.lastAccess) : account.lastAccess
                    if (account.metadata) {
                        account.metadata.lastObservation = account.metadata?.lastObservation ? new Date(account.metadata?.lastObservation) : account.metadata?.lastObservation
                        account.metadata.lastAccess = account.metadata?.lastAccess ? new Date(account.metadata?.lastAccess) : account.metadata?.lastAccess
                    }
                    account.key = account.accountId
                }
                return result;
            }))
    }

    public refreshSecretKey(sessionAuthToken: string, accountId: string): Observable<string> {
        return this.sendRequest<any>("refreshAccountSecretKey", {sessionAuthToken, accountId})
            .pipe(map((result) => result.secretKey))
    }

    public removeAccount(sessionAuthToken: string, accountId: string): Observable<boolean> {
        return this.sendRequest<any>("removeAccount", {sessionAuthToken, accountId})
            .pipe(map((result) => result.accountFieldsRemoved))
    }

    public getDeviceAdmin(sessionAuthToken: string, serialId: string): Observable<any> {
        return this.sendRequest<any>("getDeviceAdmin", {sessionAuthToken, serialId})
    }

    public findModules(sessionAuthToken: string, page: number, pageSize: number, state: ActivationState | null, radios: string[]): Observable<any> {
        return this.sendRequest<string[]>("findModules", {...{sessionAuthToken, page, pageSize}, ...{filters: {state: state, radios:radios}} })
    }

    public getLTSFirmwareVersion(sessionAuthToken: string, address: string): Observable<string> {
        return this.sendRequest<string>("getLTSFirmwareVersion", {sessionAuthToken, address});
    }

    public upgradeModuleFirmware(sessionAuthToken: string, address: string, version: string): Observable<boolean> {
        return this.sendRequest<boolean>("upgradeModuleFirmware", {sessionAuthToken, address, version});
    }

    public getModule(sessionAuthToken: string, address: string): Observable<Module> {
        return this.sendRequest<Module>("getModule", {sessionAuthToken, address})
            .pipe(map((moduleRaw) => {
                let module = new Module(moduleRaw.address, moduleRaw.properties, moduleRaw.config, moduleRaw.syncSettings, moduleRaw.fwVersionProps)
                module.props = moduleRaw.props
                if (moduleRaw.config.ism?.lorawan && module.config.ism?.lorawan) { // @ts-ignore
                    module.config.ism.lorawan.public = moduleRaw.config.ism.lorawan.isPublic
                }
                return module
        }))
    }

    public restoreModule(sessionAuthToken: string, address: string): Observable<boolean> {
        return this.sendRequest<boolean>("restoreModule", {sessionAuthToken, address})
    }

    public deregisterModule(sessionAuthToken: string, address: string): Observable<boolean> {
        return this.sendRequest<any>("deregisterModule", {sessionAuthToken, address})
            .pipe(map((result) => {
                return result?.deregistered as boolean;
            }))
    }

    /**
     * Gets a Json Object representing Synchronized Settings that contains key/value pair of syncSetting/Byte String
     * and converts that that key/value pair to syncSetting/{byte string, hex string, ascii string}
     *
     * @param sessionAuthToken
     * @param address
     */
    public getSynchronizedSettings(sessionAuthToken: string, address: string): Observable<SyncSettings> {
        return this.sendRequest<any>("getSynchronizedSettings", {sessionAuthToken, address})
            .pipe(map((syncSettings) => {
                let output = {} as SyncSettings
                for (const key in syncSettings) {
                    const value = syncSettings[key]
                    if (syncSettings.hasOwnProperty(key)) {
                        output[key] = new SyncSetting(value, key)
                    }
                }
                return output;
            }))
    }

    public setSynchronizedSetting(sessionAuthToken: string, address: string, key: string, value: string | null): Observable<any> {
        return this.sendRequest<any>("setSynchronizedSetting", {sessionAuthToken, address, key, value});
    }

    public configureModule(sessionAuthToken: string, address: string, params: object): Observable<Module["config"]> {
        return this.sendRequest<Module["config"]>("configureModule", {...{sessionAuthToken, address}, ...params})
    }

    public configureTippingBucketsAndCheckinMinutes(sessionAuthToken: string, address: string, maxMinutes: number, maxObservations: number, checkInMinutes: number): Observable<Module["config"]> {
        return this.configureModule(sessionAuthToken, address, {network: {maxMinutes: checkInMinutes}, tippingBucket: {maxMinutes: maxMinutes, maxObservations: maxObservations}})
    }

    /**
     * Used to change the user set name and note associated with a module
     *
     * @param sessionAuthToken
     * @param address
     * @param name
     * @param notes
     */
    public configureModuleInfo(sessionAuthToken: string, address: string, name: string, notes: string) : Observable<Module["config"]>{
        return this.configureModule(sessionAuthToken, address, {info: {name: name, notes: notes}})
    }

    public activateCellModule(sessionAuthToken: string, address: string): Observable<ActivationState> {
        return this.configureModule(sessionAuthToken, address, {cell: { state : ActivationState.ACTIVE }})
            .pipe(map((result) => {
                return result.cellConfig.moduleCellActivationState;
            }))
    }

    public deactivateCellModule(sessionAuthToken: string, address: string): Observable<ActivationState> {
        return this.configureModule(sessionAuthToken, address, {cell: { state : ActivationState.INACTIVE }})
            .pipe(map((result) => {
                return result.cellConfig.moduleCellActivationState;
            }))
    }

    public activateSatelliteModule(sessionAuthToken: string, address: string): Observable<ActivationState> {
        return this.configureModule(sessionAuthToken, address, {satellite: { state : ActivationState.ACTIVE }})
            .pipe(map((result) => {
                return result.satConfig.moduleSatActivationState;
            }))
    }

    public deactivateSatelliteModule(sessionAuthToken: string, address: string): Observable<ActivationState> {
        return this.configureModule(sessionAuthToken, address, {satellite: { state : ActivationState.INACTIVE }})
            .pipe(map((result) => {
                return result.satConfig.moduleSatActivationState;
            }))
    }

    public deactivateIsmModule(sessionAuthToken: string, address: string): Observable<boolean> {
        return this.configureModule(sessionAuthToken, address, {ism: { enabled: false}})
            .pipe(map((result) => {
                return !result.ism.enabled;
            }))
    }

    public registerModules(sessionAuthToken: string, addresses: string[]): Observable<any> {
        return this.sendRequest<any>("registerModules", {sessionAuthToken, addresses})
    }

    public findUsers(sessionAuthToken: string): Observable<UserForAdmin[]> {
        return this.sendRequest<UserForAdmin[]>("findUsers", {sessionAuthToken})
            .pipe(map((result) => {
                for (let x in result) {
                    result[x].key = result[x].userId
                    if (result[x].accountsInfo.length > 0) {
                        for (let y in result[x].accountsInfo) {
                            result[x].accountsInfo[y].key = result[x].accountsInfo[y].accountId
                        }
                    }
                }
                return result;
            }))
    }

    public updateUserAccountAccessForSelfAdmin(sessionAuthToken: string, accountId: string, accessLevel: string): Observable<string> {
        return this.sendRequest<string>('updateUserAccountAccess', {sessionAuthToken, accountId, accessLevel})
    }

    public updateUserAccountAccess(sessionAuthToken: string, accountId: string, email: string, accessLevel: string): Observable<string> {
        return this.sendRequest<string>('updateUserAccountAccess', {sessionAuthToken, accountId, accessLevel, email})
    }

    public deleteUser(sessionAuthToken: string, email: string): Observable<string> {
        return this.sendRequest<string>('deleteUser', {sessionAuthToken, email})
    }

    public getSystemTokens(sessionAuthToken: string): Observable<SystemToken[]> {
        return this.sendRequest<SystemToken[]>('getSystemTokens', {sessionAuthToken})
            .pipe(map((tokens) => {
                // Convert strings to dates
                for (let token of tokens) {
                    token.created = token.created ? new Date(token.created) : token.created
                    token.lastAccess = token.lastAccess ? new Date(token.lastAccess) : token.lastAccess
                }
                return tokens
            }))
    }

    public createSystemToken(sessionAuthToken: string, name: string): Observable<string> {
        return this.sendRequest<string>("createSystemToken", {sessionAuthToken, name})
    }

    public updateSystemToken(sessionAuthToken: string, params = {}): Observable<SystemToken> {
        return this.sendRequest<SystemToken>("updateSystemToken", {sessionAuthToken, ...params})
            .pipe(map((token) => {
                // Convert strings to dates
                token.created = token.created ? new Date(token.created) : token.created
                token.lastAccess = token.lastAccess ? new Date(token.lastAccess) : token.lastAccess
                return token
            }))
    }

    public removeSystemToken(sessionAuthToken: string, token: string): Observable<string> {
        return this.sendRequest<string>("removeSystemToken", {sessionAuthToken, token})
            .pipe(map((result : any) => {
                return result.removed
            }))
    }

    public getSystemLoggerMetadata(sessionAuthToken: string): Observable<SystemLoggerMetadata> { // TODO - support lte timestamp
        return this.sendRequest<any>("getSystemMetadata", {sessionAuthToken, 'key':'loggers'})
            .pipe(map((result) => {
                let out = result.metadata as SystemLoggerMetadata
                out.loggers.forEach((loggerSummary) => {
                    // Convert strings to dates
                    loggerSummary.lastOb = loggerSummary.lastOb ? new Date(loggerSummary.lastOb) : loggerSummary.lastOb
                })
                return out
            }))
    }

    /**
     * @deprecated use {@link setAccountPaymentType}
     * return true if billing is disabled, false if billing is enabled
     */
    public disableAccountBillable(sessionAuthToken: string, accountId: string, disable: boolean): Observable<boolean> {
        return this.sendRequest<boolean>("disableAccountBilling", {sessionAuthToken, accountId, disable})
    }

    // If payment type is null, the default payment type will be determined by server
    public setAccountPaymentType(sessionAuthToken: string, accountId: string, paymentType: PaymentType | null): Observable<PaymentType> {
        return this.sendRequest<any>("setAccountPaymentType", {sessionAuthToken, accountId, paymentType})
            .pipe(map((result) => {
                return result.paymentType as PaymentType;
            }))
    }

    public moveLogger(sessionAuthToken: string, serialId: string, newOwnerId: string, moveBilling: string, oldOwnerNewAccess: string, moveNetwork: string) {
        return this.sendRequest<boolean>("moveLogger", {sessionAuthToken, serialId, newOwnerId, moveBilling, oldOwnerNewAccess, moveNetwork})
    }

    public factoryResetLogger(sessionAuthToken: string, serialId: string, unmapNetworkModule: boolean, dropBilling: boolean) : Observable<boolean> {
        return this.sendRequest<any>("factoryResetLogger", {sessionAuthToken, serialId, unmapNetworkModule, dropBilling})
            .pipe(map((result) => result.complete))
    }
    
    public billAccount(sessionAuthToken: string, accountId: string, forceBill: boolean) : Observable<number> {
        return this.sendRequest<any>("billAccount", {sessionAuthToken, accountId, forceBill})
            .pipe(map((result) => {
                return result?.billed
            }))
    }

    public incrementBalance(sessionAuthToken: string, accountId: string, incrementCents: string) : Observable<number> {
        return this.sendRequest<any>("incrementBalance", {sessionAuthToken, accountId, incrementCents})
            .pipe(map((result) => {
                return result?.accountBalance
            }))
    }

    // Firmware Queries

    public findAllFirmwareVersions(sessionAuthToken: string) : Observable<string[]> {
        return this.sendRequest<any>("findFirmwareVersions", {sessionAuthToken})
            .pipe(map((result) => {
                return result?.firmwareVersions as string[]
            }))
    }

    public getFirmwareFileInfo(sessionAuthToken: string, firmwareVersion: string) : Observable<[number, Date]> {
        return this.sendRequest<any>("getFirmwareVersionInfo", {sessionAuthToken, firmwareVersion})
            .pipe(map((result) => {
                return [result?.size as number, result?.date ? new Date(result?.date) : result?.date]
            }))
    }

    public removeFirmwareFile(sessionAuthToken: string, firmwareVersion: string) : Observable<boolean> {
        return this.sendRequest<any>("removeFirmwareVersion", {sessionAuthToken, firmwareVersion})
            .pipe(map((result) => {
                return result?.removed as boolean
            }))
    }

    public uploadFirmwareFile(sessionAuthToken: string, firmwareVersion: string, data: string) : Observable<boolean> {
        return this.sendRequest<any>("uploadFirmwareVersion", {sessionAuthToken, firmwareVersion, data})
            .pipe(map((result) => {
                return result?.uploaded as boolean
            }))
    }

    public readFirmwareVersion(sessionAuthToken: string, firmwareVersion: string) : Observable<Blob> {
        return this.sendRequest("readFirmwareVersion", {sessionAuthToken, firmwareVersion})
            .pipe(map((result: any) => {
                if (result?.data == null)
                    return new Blob()
                const byteArray = new Uint8Array(atob(result.data).split('').map(char => char.charCodeAt(0)));
                return new Blob([byteArray], {type: 'application/octet-stream'});
            }))
    }

    public upgradeLoggerFirmware(apiToken: string, serialId: string, firmwareVersion: number, fromAdmin: boolean): Observable<boolean> {
        return this.sendRequest<boolean>(fromAdmin ? "upgradeLoggerFirmwareAdmin" : "upgradeLoggerFirmware", {...(fromAdmin ? {sessionAuthToken: apiToken} : {apiToken: apiToken}), serialId, firmwareVersion: "v" + String(firmwareVersion)})
    }

}

export default ResponseHandler;
export const api = ApiClient.Instance;