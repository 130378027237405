import React, {Suspense, useEffect, useState} from 'react';
import {BrowserRouter as Router, Link, Redirect, Route, Switch} from "react-router-dom";
import SideNav from "../components/layouts/sidebar";
import {Button, Col, Layout, Popover, Row, Spin, Tooltip} from 'antd';
import {
    LogoutOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    QuestionCircleOutlined,
    SettingOutlined
} from '@ant-design/icons';
import {session} from "../service/session";
import AccountSelect from "../components/pages/account/accountSelect";
import GEOKON_BLUE from "../index";
import AdminSessionNotification from "../components/pages/admin/util/adminSessionNotification";

const AdminPanel = React.lazy(() => import("../components/pages/admin/adminPanel"))
const DevicesPanel = React.lazy(() => import("../components/pages/device/devices"))
const Settings = React.lazy(() => import("../components/pages/settings"))
const ConnectionsPage = React.lazy(() => import("../components/pages/connections/connections"))
const BillingPanel = React.lazy(() => import("../components/pages/billing/billing"))
const LoggerConfig = React.lazy(() => import("../components/pages/device/clientLoggerConfig"))
const DataPanel = React.lazy(() => import("../components/pages/data/dataPanel"))

const { Header, Sider, Content} = Layout;

const shortcutIconStyles = {
    marginLeft: 8
}

const collapseHeight = () => {return session.isAdminSession() ? "calc(100vh - 280px)" : "calc(100vh - 280px + 40px)"};
const collapseStyles = () => { return {
    fontSize: "25px",
    height: collapseHeight(),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    paddingBottom: 30,
    background: "none"
}}

const ApplicationRoutes = () => {


    const demoCollapse =  window.innerWidth > 760 && !session.isContinuedSession()
    const [collapse, setCollapse] = useState(!demoCollapse);
    useEffect(() => {
        if (demoCollapse)
            setTimeout(() => setCollapse(true), 1500);
    }, []);
    const handleToggle = (event: any) => {
        event.preventDefault();
        collapse ? setCollapse(false) : setCollapse(true);
    }

    return (
        <Router>
            <Layout>
                {session.isAdminSession() ? <AdminSessionNotification/> : null}

                <Sider theme="light" trigger={null} collapsible collapsed={collapse}>
                    <SideNav/>
                    <Button type={"text"} size={"large"} icon={collapse ? <MenuUnfoldOutlined style={{fontSize: "25px"}}/> : <MenuFoldOutlined style={{fontSize: "25px"}}/>} onClick={handleToggle} style={collapseStyles()}/>
                </Sider>
                <Layout style={session.isAdminSession() ? {background: GEOKON_BLUE.replace(',1)', ',0.5')} : {background: (session.isDarkSession() ? 'rgb(30,30,30)': '')}}>
                    <Header className="siteLayoutBackground" style={{padding: 0, background: (session.getUserSettings().darkMode ? '#141414' : 'white')}}>
                        <Row justify="end">
                            <Col span={24} style={{textAlign: 'right', paddingRight: 50}}>
                                <Tooltip title={'Viewing API Account:'} placement={'left'}>
                                    <div style={{display: 'inline', textAlign: "left", paddingRight: 5}}>
                                        <AccountSelect/>
                                    </div>
                                </Tooltip>

                                <Popover content={
                                    <>
                                        <Tooltip title={"View our documentation"}>
                                            <Button type={"primary"} onClick={() => window.open("https://apidocs.geokon.com", "_blank")} style={{marginRight: 10}}>
                                                Api Docs
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={"Contact our technical support team"}>
                                            <Button type={"primary"} onClick={() => window.open('https://www.geokon.com/Technical-Support', '_blank')}>
                                                Get Support
                                            </Button>
                                        </Tooltip>
                                    </>
                                }>
                                    <Button type="primary" shape="circle" icon={<QuestionCircleOutlined/>} style={shortcutIconStyles}/>
                                </Popover>
                                <Tooltip title={"Settings"}>
                                    <Link to={"/settings"}>
                                        <Button type="primary" shape="circle" icon={<SettingOutlined/>} style={shortcutIconStyles}/>
                                    </Link>
                                </Tooltip>
                                <Tooltip title={"Log out"}>
                                    <Button type="primary" shape="circle" icon={<LogoutOutlined/>} style={shortcutIconStyles}
                                            onClick={session.endSession}/>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Header>
                    <Content style={{
                        margin: '20px 16px 0px 16px',
                        padding: '16px 20px 0px 20px',
                        height: "calc(100vh - 84px)",
                        background: session.getUserSettings()?.darkMode ? 'black' : "#fff",
                        overflowY: 'scroll'
                    }}>
                        <Switch>
                            <Route path="/settings"><Suspense fallback={<Spin/>}><Settings/></Suspense></Route>
                            <Route path="/devices"><Suspense fallback={<Spin/>}><DevicesPanel/></Suspense></Route>
                            <Route path="/device/*"><Suspense fallback={<Spin/>}><LoggerConfig/></Suspense></Route>
                            <Route path="/connections"><Suspense fallback={<Spin/>}><ConnectionsPage/></Suspense></Route>
                            <Route path="/billing"><Suspense fallback={<Spin/>}><BillingPanel/></Suspense></Route>
                            <Route path="/admin"><Suspense fallback={<Spin/>}><AdminPanel/></Suspense></Route>
                            <Route path="/data"><Suspense fallback={<Spin/>}><DataPanel/></Suspense></Route>
                            <Redirect to="/devices" from="/"/>
                        </Switch>

                    </Content>
                </Layout>
            </Layout>
        </Router>
    );
}

export default ApplicationRoutes;